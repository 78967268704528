import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { button_value, LINK_OF_ALL_PAGES } from "const/common";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import YourConcept from "./YourConcept";

const DesktopMegaMenu = () => {
  const router = useRouter();
  const [isTechHover, setIsTechHover] = useState(1);

  const handleEnterMouse = (id) => {
    setIsTechHover(id);
  };
  const handleLeaveMouse = () => {
    setIsTechHover(1);
  };
  return (
    <nav className="flex justify-between items-center relative">
      <Link href={LINK_OF_ALL_PAGES.mainPages.home}>
        <a>
          <div className="fs-0 cursor-pointer">
            <Image
              alt="Logo"
              height={32}
              src="/assets/common/unt-logo.svg"
              width={236}
            />
          </div>
        </a>
      </Link>
      <div className="flex gap-6 items-center">
        <div className="py-8 group flex items-center">
          <Link href={LINK_OF_ALL_PAGES.services.servicesMain}>
            <a
              className={`${
                router.pathname === LINK_OF_ALL_PAGES.services.servicesMain
                  ? "text-primary-yellow"
                  : "text-white"
              } text-base font-normal transition-all duration-500 ease-out hover:ease-in mr-3 group-hover:text-primary-yellow`}
            >
              <span>Services</span>
            </a>
          </Link>
          <div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="group-hover:text-primary-yellow text-white group-hover:rotate-180"
              width={14}
              height={14}
            />
          </div>
          <div
            className="-left-12 absolute py-12 px-16 hidden bg-black-chocolate z-20 top-[5.5rem] pt-11 pb-16 group-hover:flex"
            onMouseLeave={() => {
              handleLeaveMouse(1);
            }}
          >
            <div className="pr-7 border border-b-0 border-t-0 border-l-0 border-gray-300/30 flex flex-col w-4/12">
              <Link href={LINK_OF_ALL_PAGES.services.customSoftwareDevelopment}>
                <a
                  className={`text-lg font-normal text-nowrap ${
                    isTechHover === 1 ? "text-primary-yellow" : "text-white"
                  }`}
                  onMouseEnter={() => {
                    handleEnterMouse(1);
                  }}
                >
                  <span>Custom Software Development</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.uiUXDesignServices}>
                <a
                  className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                  onMouseEnter={() => {
                    handleEnterMouse(0);
                  }}
                >
                  <span>UI-UX Designing</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.digitalMarketing}>
                <a
                  className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                  onMouseEnter={() => {
                    handleEnterMouse(0);
                  }}
                >
                  <span>Digital Marketing</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.itConsultationAssistance}>
                <a
                  className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                  onMouseEnter={() => {
                    handleEnterMouse(0);
                  }}
                >
                  <span>IT Consultation</span>
                </a>
              </Link>
              <Link href={LINK_OF_ALL_PAGES.services.staffAugmentation}>
                <a
                  className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                  onMouseEnter={() => {
                    handleEnterMouse(0);
                  }}
                >
                  <span>IT Staff Augmentation</span>
                </a>
              </Link>
            </div>
            <div className={isTechHover === 1 ? "block" : "hidden"} id={1}>
              <div className="pl-12 flex flex-col">
                <Link href={LINK_OF_ALL_PAGES.services.webApplicationDevelopmentServices}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Web App Development</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.mobileApplicationDevelopmentServices}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Mobile App Development</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.cloudAndDevopsServices}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Cloud & DevOps</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.enterpriseSoftwareDevelopmentServices}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Enterprise Software Development</span>
                  </a>
                </Link>
              </div>
            </div>
            <YourConcept href={LINK_OF_ALL_PAGES.services.servicesMain} label="Explore All Services" />
          </div>
        </div>
        <div className="py-8 group flex items-center">
          <p className="text-base font-normal transition-all duration-500 text-white ease-out hover:ease-in mr-3 group-hover:text-primary-yellow">
            <span>Data Solution</span>
          </p>
          <div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="group-hover:text-primary-yellow text-white group-hover:rotate-180"
              width={14}
              height={14}
            />
          </div>
          <div
            className="left-1/2 -translate-x-1/2 absolute py-12 px-16 hidden bg-black-chocolate z-20 top-[5.5rem] pt-11 pb-16 group-hover:flex"
            onMouseLeave={() => {
              handleLeaveMouse(1);
            }}
          >
            <div className="pr-7 border border-b-0 border-t-0 border-l-0 border-gray-300/30 flex flex-col w-4/12">
              <Link href={LINK_OF_ALL_PAGES.mainPages.dataSolution}>
                <a
                  className={`text-lg font-normal text-nowrap ${
                    isTechHover === 1 ? "text-primary-yellow" : "text-white"
                  }`}
                  onMouseEnter={() => {
                    handleEnterMouse(1);
                  }}
                >
                  <span>Data Scraping</span>
                </a>
              </Link>
            </div>
            <div className={isTechHover === 1 ? "block" : "hidden"} id={1}>
              <div className="pl-12 flex flex-col">
                <Link href={LINK_OF_ALL_PAGES.services.enterPriseWebScrapingService}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Enterprise Web Scraping</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.mobileAppScrapingServices}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Mobile App Scraping</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.webScrapingApi}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Web Scraping API</span>
                  </a>
                </Link>
              </div>
            </div>
            <div>
              <YourConcept
                href={LINK_OF_ALL_PAGES.mainPages.dataSolution}
                label="Get Free Sample Data"
              />
            </div>
          </div>
        </div>
        <div className="py-8 group flex items-center">
          <Link href={LINK_OF_ALL_PAGES.mainPages.expertise}>
            <a
              className={`${
                router.pathname === LINK_OF_ALL_PAGES.mainPages.expertise
                  ? "text-primary-yellow"
                  : "text-white"
              } text-base font-normal transition-all duration-500 ease-out hover:ease-in mr-3 group-hover:text-primary-yellow`}
            >
              <span>Hire Developers</span>
            </a>
          </Link>
          <div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="group-hover:text-primary-yellow text-white group-hover:rotate-180"
              width={14}
              height={14}
            />
          </div>
          <div
            className="left-1/2 -translate-x-1/2 absolute py-12 px-16 hidden bg-black-chocolate z-20 top-[5.5rem] pt-11 pb-16 group-hover:flex"
            onMouseLeave={() => {
              handleLeaveMouse(1);
            }}
          >
            <div className="pr-7 border border-b-0 border-t-0 border-l-0 border-gray-300/30 flex flex-col w-4/12">
              <p
                className={`text-lg font-normal text-nowrap ${
                  isTechHover === 1 ? "text-primary-yellow" : "text-white"
                }`}
                onMouseEnter={() => {
                  handleEnterMouse(1);
                }}
              >
                <span>Full Stack</span>
              </p>
              <p
                className={`text-lg font-normal text-nowrap pt-5 ${
                  isTechHover === 2 ? "text-primary-yellow" : "text-white"
                }`}
                onMouseEnter={() => {
                  handleEnterMouse(2);
                }}
              >
                <span>Front End</span>
              </p>
              <p
                className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                onMouseEnter={() => {
                  handleEnterMouse(3);
                }}
              >
                <span>Back End</span>
              </p>
              <p
                className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                onMouseEnter={() => {
                  handleEnterMouse(4);
                }}
              >
                <span>Mobile App</span>
              </p>
              <p
                className="text-lg font-normal text-white pt-5 hover:text-primary-yellow text-nowrap"
                onMouseEnter={() => {
                  handleEnterMouse(5);
                }}
              >
                <span>Open Source</span>
              </p>
            </div>
            <div
              className={`transition-all duration-500 ${
                isTechHover === 1 ? "block ease-in" : "hidden ease-out"
              }`}
              id={1}
            >
              <div className="pl-12 flex flex-col min-w-60">
                <Link href={LINK_OF_ALL_PAGES.services.hireMeanDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Mean Stack Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireMernDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Mern Stack Developer</span>
                  </a>
                </Link>
              </div>
            </div>
            <div
              className={`transition-all duration-500 ${
                isTechHover === 2 ? "block ease-in" : "hidden ease-out"
              }`}
              id={2}
            >
              <div className="pl-12 flex flex-col min-w-60">
                <Link href={LINK_OF_ALL_PAGES.services.hireReactJSDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>React JS Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireAngularDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Angular JS Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireNextJSDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Next JS Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireVueJSDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Vue JS Developer</span>
                  </a>
                </Link>
              </div>
            </div>
            <div
              className={`transition-all duration-500 ${
                isTechHover === 3 ? "block ease-in" : "hidden ease-out"
              }`}
              id={3}
            >
              <div className="pl-12 flex flex-col min-w-60">
                <Link href={LINK_OF_ALL_PAGES.services.hireNodeJSDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Node JS Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireRoRDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Ruby on Rails Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hirePythonDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Python Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireGolangDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Golang Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireLaravelDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Laravel Developer</span>
                  </a>
                </Link>
              </div>
            </div>
            <div
              className={`transition-all duration-500 ${
                isTechHover === 4 ? "block ease-in" : "hidden ease-out"
              }`}
              id={4}
            >
              <div className="pl-12 flex flex-col min-w-60">
                <Link href={LINK_OF_ALL_PAGES.services.hireAndroidDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Android Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireiOSDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>IOS Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireKotlinDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Kotlin Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireSwiftDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Swift Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireFlutterDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Flutter Developer</span>
                  </a>
                </Link>
                <Link href={LINK_OF_ALL_PAGES.services.hireReactNativeDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>React Native Developer</span>
                  </a>
                </Link>
              </div>
            </div>
            <div
              className={`transition-all duration-500 ${
                isTechHover === 5 ? "block ease-in" : "hidden ease-out"
              }`}
              id={5}
            >
              <div className="pl-12 min-w-60">
                <Link href={LINK_OF_ALL_PAGES.services.hireShopifyDeveloper}>
                  <a className="text-lg font-normal text-white text-nowrap pb-4 hover:text-primary-yellow">
                    <span>Shopify Developer</span>
                  </a>
                </Link>
              </div>
            </div>
            <div>
              <YourConcept href={LINK_OF_ALL_PAGES.mainPages.expertise} label="EXPLORE ALL" />
            </div>
          </div>
        </div>
        <Link href={LINK_OF_ALL_PAGES.mainPages.portfolio}>
          <a
            className={`text-base font-normal transition-all duration-500 ease-out hover:ease-in ${
              router.pathname === LINK_OF_ALL_PAGES.mainPages.portfolio
                ? "text-primary-yellow"
                : "text-white hover:text-primary-yellow"
            }`}
          >
            <span>Portfolio</span>
          </a>
        </Link>
        <Link href={LINK_OF_ALL_PAGES.mainPages.aboutUltroneous}>
          <a
            className={`text-base font-normal transition-all duration-500 ease-out hover:ease-in ${
              router.pathname === LINK_OF_ALL_PAGES.mainPages.aboutUltroneous
                ? "text-primary-yellow"
                : "text-white hover:text-primary-yellow"
            }`}
          >
            <span>About Us</span>
          </a>
        </Link>
        <Link href={LINK_OF_ALL_PAGES.mainPages.blog}>
          <a
            className={`text-base font-normal transition-all duration-500 ease-out hover:ease-in ${
              router.pathname === LINK_OF_ALL_PAGES.mainPages.blog
                ? "text-primary-yellow"
                : "text-white hover:text-primary-yellow"
            }`}
          >
            <span>Blog</span>
          </a>
        </Link>
        <Link href={LINK_OF_ALL_PAGES.mainPages.contactUs}>
          <a className="text-lg font-semibold text-white py-3 px-7 border border-solid border-primary-yellow bg-primary-yellow hover:bg-transparent hover:text-primary-yellow transition-all ease-out duration-500 hover:ease-in rounded-lg ml-24">
            {button_value}
          </a>
        </Link>
      </div>
    </nav>
  );
};

export default DesktopMegaMenu;
